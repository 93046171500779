import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

const navItemHorizontalSpacing = "20px"

export const NavBarContainer = styled(MainColumn)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 90px;
  align-items: center;

  @media ${device.tablet} {
    height: 120px;
  }
`

export const LogoWrapper = styled(Link)`
  display: block;

  @media ${device.tablet} {
    margin-bottom: -4px;
  }
  h1 {
    font-size: 20px;
    font-weight: 400;

    @media ${device.desktop} {
      font-size: 24px;
    }

    span {
      color: #767676;
      font-weight: 400;
    }
  }
`

export const NavWrapper = styled.div`
  display: flex;
`

export const Nav = styled.nav`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  margin: 0;
  background: #fff;
  width: 100%;
  height: 100vh;
  transform: ${props =>
    props.isNavShown ? "translate3d(0,0%, 0)" : "translate3d(-100%, 0%, 0)"};
  transition: 600ms;
  pointer-events: ${props => (props.isNavShown ? "all" : "none")};
  z-index: 2;
  padding-top: 96px;
  @media ${device.tablet} {
    display: none;
    /* opacity: 1;
    position: relative;
    bottom: auto;
    background: none;
    transform: none;
    justify-content: flex-end; */
  }
`

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
`

export const NavListItem = styled.li`
  width: 100%;
  a {
    display: block;
    padding: 10px ${navItemHorizontalSpacing};
    font-weight: 400;
    font-size: 22px;
    color: ${props => props.theme.linkColor};

    @media ${device.tablet} {
      pointer-events: all;
    }
  }

  span {
    position: relative;
    ::before {
      content: "";
      position: absolute;
      left: 0;
      opacity: 0;
      height: 2px;
      width: 100%;
      bottom: -6px;
      transition: 200ms;
      background: ${props => props.theme.linkColor};
    }
  }

  :hover {
    span::before {
      opacity: 1;
      bottom: -3px;
    }
  }
`

export const NavButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  text-decoration: underline;
  font-size: 20px;
  cursor: pointer;
  @media ${device.tablet} {
    display: none;
  }
`

export const CloseNavButton = styled(NavButton)`
  position: absolute;
  top: 30px;
  right: 15px;
`
export const FixingFineButtonContainer = styled.a`
  display: none;
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      height: auto;
    }
  }
`
export const FixingFineButton = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin-right: 15px;
  text-decoration: underline;
`
