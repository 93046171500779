import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const FooterContainer = styled.div`
  background: white;
  width: 100%;
`

export const FooterMainColumn = styled(MainColumn)`
  display: flex;
  align-items: center;
  flex-direction:row;
  height: 63px;

  @media ${device.desktop} {
    height: 100px;
  }
  a {
    font-size: 16px;
    text-decoration: underline;
    margin-right: 15px;
    color:black;
    @media ${device.desktop} {
      font-size: 18px;
    margin-right: 30px;

    }
  }
`
