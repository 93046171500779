import React, { useState, useEffect } from "react"
import { ProgressMarker, Label } from "./style"
const Marker = ({ currentPosition, position, label }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (currentPosition >= position) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [currentPosition])
  return (
    <ProgressMarker active={active} position={position}>
      <Label>{label}</Label>
    </ProgressMarker>
  )
}

export default Marker
