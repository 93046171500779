import React from "react"

import { NavBar } from "../NavBar"
import { HeaderContainer } from "./style"
import { Link } from "gatsby"

const Header = ({ nsw, questions, results }) => {
  return (
    <HeaderContainer id="top-of-page">
      <NavBar questions={questions} nsw={nsw} results={results} />
    </HeaderContainer>
  )
}

export default Header
