import React, { useState } from "react"
import { ReactComponent as LongArrow } from "../../images/icons/long-arrow.svg"
import {
  LogoWrapper,
  NavBarContainer,
  NavWrapper,
  Nav,
  NavList,
  NavButton,
  CloseNavButton,
  FixingFineButton,
  FixingFineButtonContainer,
} from "./style"
import NavItem from "./navItem"
import { ProgressBar } from "components/ProgressBar"
import { Link } from "gatsby"

const NavBar = ({ questions, nsw, vic, results }) => {
  const [isNavShown, setNavShown] = useState(false)

  const toggleShowNav = () => {
    setNavShown(!isNavShown)
  }

  return (
    <NavBarContainer>
      <LogoWrapper to="/">
        <h1>
          FineFixer.org.au
        </h1>
      </LogoWrapper>
      <NavWrapper>
        <Nav isNavShown={isNavShown}>
          <CloseNavButton onClick={toggleShowNav}>Close</CloseNavButton>

          <NavList>
            <NavItem label="About FineFiner" path="/" />
            <NavItem label="Terms of Use & Privacy" path="/" />
          </NavList>
        </Nav>

        <NavButton onClick={toggleShowNav}>menu</NavButton>
      </NavWrapper>
      {results && (
        <FixingFineButtonContainer href="/">
          <FixingFineButton>Start fixing a fine</FixingFineButton>

          <LongArrow />
        </FixingFineButtonContainer>
      )}
      {questions && <ProgressBar />}
    </NavBarContainer>
  )
}

export default NavBar
