import React, { useState } from "react"
import { ProgressBarContainer } from "./style"
import Marker from "./Marker"
const ProgressBar = () => {
  const [currentPosition, setCurrentPosition] = useState(0)
  return (
    <ProgressBarContainer currentPosition={currentPosition}>
      <Marker currentPosition={currentPosition} label="My fine" position={0} />
      <Marker
        currentPosition={currentPosition}
        label="My details"
        position={30}
      />
      <Marker
        currentPosition={currentPosition}
        label="My options"
        position={100}
      />

      {/* <div className="buttons">
        <button onClick={() => setCurrentPosition(c => c - 5)}>minus</button>
        <button onClick={() => setCurrentPosition(c => c + 5)}>plus</button>
      </div> */}
    </ProgressBarContainer>
  )
}

export default ProgressBar
